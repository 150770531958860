import { Meta, MetaDefinition } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor(private meta: Meta) {
  }

  public addSocialMetaTags(type: string = 'talent'): void {
    const urlPrefix = environment.production ? 'https://bazait.com' : 'https://bazait.xyz';
    const url = type === 'talent'
      ? `${urlPrefix}`
      : `${urlPrefix}/company`;
    const imageUrl = type === 'talent'
      ? `${urlPrefix}/assets/images/talent-share.png`
      : `${urlPrefix}/assets/images/company-share.png`;
    const title = type === 'talent'
      ? 'BazaIT | Talent'
      : 'BazaIT | Company';

    const tags: MetaDefinition[] = [
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:url',
        content: url,
      },
      {
        name: 'twitter:title',
        content: title
      },
      {
        name: 'twitter:image',
        content: imageUrl
      },
      {
        name: 'title',
        property: 'og:title',
        content: title
      },
      {
        name: 'type',
        property: 'og:type',
        content: 'website'
      },
      {
        name: 'url',
        property: 'og:url',
        content: url,
      },
      {
        name: 'locale',
        property: 'og:locale',
        content: 'en_US'
      },
      {
        name: 'locale:alternate',
        property: 'og:locale:alternate',
        content: 'uk_UA'
      },
      {
        name: 'image',
        property: 'og:image',
        content: imageUrl,
      }
    ];

    this.meta.addTags(tags);
  }

  public updateMetaTags(type: string = 'talent'): void {
    const urlPrefix = environment.production ? 'https://bazait.com' : 'https://bazait.xyz';
    const url = type === 'talent'
      ? `${urlPrefix}`
      : `${urlPrefix}/company`;
    const imageUrl = type === 'talent'
      ? `${urlPrefix}/assets/images/talent-share.png`
      : `${urlPrefix}/assets/images/company-share.png`;
    const title = type === 'talent'
      ? 'BazaIT | Talent'
      : 'BazaIT | Company';

    this.meta.updateTag({name: 'twitter:url', content: url});
    this.meta.updateTag({name: 'twitter:image', content: imageUrl});
    this.meta.updateTag({name: 'url', property: 'og:url', content: url});
    this.meta.updateTag({name: 'image', property: 'og:image', content: imageUrl});
    this.meta.updateTag({name: 'title', property: 'og:title', content: title});
    this.meta.updateTag({name: 'twitter:title', content: title});
  }
}
