import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, Scroll } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { footerLinks } from 'src/app/mocks/footerLinks';
import { socialLinks } from 'src/app/mocks/socialLinks';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';

import { HelperService } from '../../../shared/services/helper.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public footerLinks = footerLinks;
  public socialLinks = socialLinks;
  public typeHeader = 'white'

  private unsubscribe$ = new Subject();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private translateService: TranslateService,
    private viewportScroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private router: Router,
    private title: Title,
    private meta: Meta,
  ) {
    router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.typeHeader = event.url === '/' ? 'white' : 'black';
      });
  }

  ngOnInit(): void {
    this.initScrolls();
    this.setLanguage();
    this.initTitles();
    this.helperService.addSocialMetaTags();

    if (!environment.production) {
      this.meta.addTag({name: 'robots', content: 'noindex, nofollow'});
    }
  }

  private initTitles(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        const isCompany = event.url.includes('company');
        this.setPageTitle();
        this.setPageDescription();
        this.updateCanonicalUrl(this.document.URL);
        this.helperService.updateMetaTags(isCompany ? 'company' : 'talent');
      });
  }

  private async setPageTitle(): Promise<any> {
    const routeTitle = await this.getTitle(this.activatedRoute.snapshot);
    let title = environment.baseTitle;

    if (routeTitle.length) {
      title += ` | ${routeTitle}`;
    }

    this.title.setTitle(title);
  }

  private async setPageDescription(): Promise<any> {
    const content = this.translateService.instant('meta.description');

    this.meta.updateTag({name: 'description', content});
    this.meta.updateTag({name: 'twitter:description', content})
    this.meta.updateTag({name: 'og:description', content})
  }

  private updateCanonicalUrl(url: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let element = this.document.querySelector(`link[rel='canonical']`) || null

    if (element === null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }

    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }


  private async getTitle(snapshot: ActivatedRouteSnapshot): Promise<any> {
    if (snapshot && snapshot.children && snapshot.children.length > 0) {
      return this.getTitle(snapshot.children[0]);
    } else if (snapshot.data && snapshot.data['title']) {
      return await this.translateService.get(snapshot.data['title']).toPromise();
    } else {
      return '';
    }
  }

  private setLanguage(): void {
    if (isPlatformBrowser(this.platformId)) {
      const language = localStorage.getItem('language');
      this.translateService.use(language ? language : 'en');
    } else {
      this.translateService.use('en');
    }
  }

  private initScrolls(): void {
    this.router.events.pipe(
      takeUntil(this.unsubscribe$),
      filter((e): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        this.viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        this.viewportScroller.scrollToAnchor(e.anchor);
      } else {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
